import { Teacher, Workload, WorkloadMiddleware } from "../types";

// map teachers to a workload
export function createWorkloads(teachers: Teacher[]): Workload[] {
  return teachers.map(teacher => ({
    teacher,
  }));
}

// each teacher can work 5 times a week
export const dayMiddleware: WorkloadMiddleware = (workloads: Workload[]): Workload[] => {
  const newWorkloads: Workload[] = [];

  workloads.forEach(workload => {
    for (let i = 0; i < 5; i++) {
      newWorkloads.push({
        teacher: workload.teacher,
        day: i + 1
      });
    }
  });

  return newWorkloads;
}

// each school day has 9 periods
export const periodMiddleware: WorkloadMiddleware = (workloads: Workload[]): Workload[] => {
  const newWorkloads: Workload[] = [];

  workloads.forEach(workload => {
    for (let i = 0; i < 9; i++) {
      newWorkloads.push({
        teacher: workload.teacher,
        day: workload.day,
        period: i + 1
      });
    }
  });

  return newWorkloads;
}

// teacher's schedule can be EARLY or LATE
// EARLY teachers can be scheduled from periods 1 to 8, but not period 9
// LATE teachers can be scheduled from periods 2 to 9, but not period 1
export const scheduleMiddleware: WorkloadMiddleware = (workloads: Workload[]): Workload[] => {
  const newWorkloads: Workload[] = [];

  workloads.forEach(workload => {
    if (workload.teacher.schedule === 'EARLY' && workload.period === 9) {
      return;
    }

    if (workload.teacher.schedule === 'LATE' && workload.period === 1) {
      return;
    }

    newWorkloads.push(workload);
  });

  return newWorkloads;
}
