// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: Arial, sans-serif;
    margin: 20px;
}
.container {
    max-width: 600px;
    margin: auto;
    text-align: center;
}
input[type="file"] {
    margin-top: 20px;
}
table {
    margin: 20px auto;
    border-collapse: collapse;
    width: 100%;
}
th, td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}
th {
    background-color: #f2f2f2;
}
.result-container {
    margin-top: 20px;
}

.actions {
  display: flex;
  justify-content: space-between;
}

label {
  cursor: pointer;
  margin-right: 30px;
}

.filters {
  display: flex;
  gap: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,WAAW;AACf;AACA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,gBAAgB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,UAAU;AACZ","sourcesContent":["body {\n    font-family: Arial, sans-serif;\n    margin: 20px;\n}\n.container {\n    max-width: 600px;\n    margin: auto;\n    text-align: center;\n}\ninput[type=\"file\"] {\n    margin-top: 20px;\n}\ntable {\n    margin: 20px auto;\n    border-collapse: collapse;\n    width: 100%;\n}\nth, td {\n    border: 1px solid #dddddd;\n    text-align: left;\n    padding: 8px;\n}\nth {\n    background-color: #f2f2f2;\n}\n.result-container {\n    margin-top: 20px;\n}\n\n.actions {\n  display: flex;\n  justify-content: space-between;\n}\n\nlabel {\n  cursor: pointer;\n  margin-right: 30px;\n}\n\n.filters {\n  display: flex;\n  gap: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
