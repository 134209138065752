import { useState } from "react";
import Papa from "papaparse";
import type { ParseMeta } from "papaparse";
import type { IUseJSONParser } from "../types";

export function useJSONParser<T>(): IUseJSONParser<T> {
	const [data, setData] = useState<T[]>();
	const [meta, setMeta] = useState<ParseMeta>();
	const [loading, setLoading] = useState(false);

	function parse(file: File) {
		if (!file) return;

		setLoading(true);
		const reader = new FileReader();

		reader.onload = (e) => {
			const csvString = e.target?.result?.toString() || "";
			const { data, meta } = Papa.parse<T>(csvString, {
				header: true,
				dynamicTyping: true,
			});
			setData(data);
			setMeta(meta);
			setLoading(false);
		};

		reader.readAsText(file);
	}

	return {
		loading,
		parse,
		data,
		meta,
	};
}
