import { Course, Section, Teacher, Workload } from "../types"

export function assignSectionToWorkloads(section: Section, workloads: Workload[]) {
  if (workloads.length) {
    const workload = workloads.find(workload => teacherHasCourse(workload.teacher, section.course))

    if (workload) {
      workload.section = section;
    }
  }
  else {
    console.warn(`Section ${section.id} of course ${section.course.name} has no workload available.`);
  }
}

export function teacherHasCourse(teacher: Teacher, course: Course) {
  return !!teacher.courses.find(courses => {
    return courses.find(c => {
      return c.code === course.code;
    });
  });
}
