// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scheduler {
  width: 100%;
  margin: 0 auto;
}

.scheduler__input {
  display: flex;
}

.scheduler__upload {
  flex: 0 1 25%;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.scheduler__generate {
  width: 100%;
  margin-top: 20px;
  background-color: #000;
  border: none;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}
.scheduler__table-wrapper {
  overflow-y: auto;
  width: 100%;
}

.scheduler__table td:first-child {
  min-width: 50px;
}

.scheduler__table td:not(:first-child) {
  min-width: 300px;
}

.scheduler__teacher-table th:first-child {
  max-width: 200px;
  min-width: 200px;
  width: 200px;
}

.scheduler__teacher-table th:nth-child(2) {
  width: 50px;
}

.scheduler__teacher-table th:not(:first-child):not(:nth-child(2)) {
  width: 100px;
}


`, "",{"version":3,"sources":["webpack://./src/components/Scheduler.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd","sourcesContent":[".scheduler {\n  width: 100%;\n  margin: 0 auto;\n}\n\n.scheduler__input {\n  display: flex;\n}\n\n.scheduler__upload {\n  flex: 0 1 25%;\n  display: flex;\n  flex-direction: column;\n  font-weight: bold;\n}\n\n.scheduler__generate {\n  width: 100%;\n  margin-top: 20px;\n  background-color: #000;\n  border: none;\n  color: #fff;\n  padding: 10px;\n  cursor: pointer;\n}\n.scheduler__table-wrapper {\n  overflow-y: auto;\n  width: 100%;\n}\n\n.scheduler__table td:first-child {\n  min-width: 50px;\n}\n\n.scheduler__table td:not(:first-child) {\n  min-width: 300px;\n}\n\n.scheduler__teacher-table th:first-child {\n  max-width: 200px;\n  min-width: 200px;\n  width: 200px;\n}\n\n.scheduler__teacher-table th:nth-child(2) {\n  width: 50px;\n}\n\n.scheduler__teacher-table th:not(:first-child):not(:nth-child(2)) {\n  width: 100px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
