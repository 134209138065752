import { useEffect } from "react";
import { ParseMeta } from "papaparse";

import { useJSONParser } from "./useJSONParser"
import { CourseCSV, StudentsCSV, TeacherCSV } from "../types";

export const useSchedulerInput = () => {
  const students = useJSONParser<StudentsCSV>();
  const teachers = useJSONParser<TeacherCSV>();
  const courses = useJSONParser<CourseCSV>();
  const rooms = useJSONParser();

  useEffect(() => {
    validate('Student course preferences', students.data, students.meta, ['Student ID']);
  }, [students]);

  useEffect(() => {
    validate('Teacher courses', teachers.data, teachers.meta, ['Schedule', 'Teacher']);
  }, [teachers]);

  useEffect(() => {
    validate('Course codes', courses.data, courses.meta, ['Course Code', 'Course Name', '# Meeting Times', 'Room Locations']);
  }, [courses]);

  useEffect(() => {
    validate('Rooms', rooms.data, rooms.meta, ['Teacher', 'Main Room', 'Possible Rooms']);
  }, [rooms]);

  function validate(name: string, data: any, meta: ParseMeta | undefined, requiredFields: string[]) {
    if (data) {
      if (data.length === 0) {
        console.warn(`${name} is empty!`);
      }

      requiredFields.forEach((field) => {
        if (!meta?.fields?.includes(field)) {
          console.warn(`${field} not found!`);
        }
      });
    }
  }

  return {
    students,
    teachers,
    courses,
    rooms
  }
}
