import { ScheduleMiddleware, Section, Workload } from "../types";
import { teacherHasCourse } from "./helpers";

// get only workloads without assignment
export const unassignedMiddleware: ScheduleMiddleware = (_, workloads: Workload[]): Workload[] => {
  return workloads.filter((workloads) => {
    return !workloads.section;
  });
}

// section should meet at most once a day
export const onceADayMiddleware: ScheduleMiddleware = (section: Section, workloads: Workload[], schedule: Workload[]): Workload[] => {
  const newWorkloads: Workload[] = [];
  const assignedDays: number[] = [];

  schedule.forEach((workload) => {
    if (workload.section && workload.day && workload.section?.id === section.id && workload.section?.course.code === section.course.code) {
      assignedDays.push(workload.day);
    }
  });

  workloads.forEach(workload => {
    if (!assignedDays.includes(workload.day!)) {
      newWorkloads.push(workload);
    }
  });

  return newWorkloads;
}

// get workloads that has teachers that can teach the course
export const teacherCoursesMiddleware: ScheduleMiddleware = (section: Section, workloads: Workload[]): Workload[] => {
  return workloads.filter(workload => {
    return teacherHasCourse(workload.teacher, section.course);
  });
}

// teachers can only teach a number of course per day
export const teacherCoursesLimitMiddleware: ScheduleMiddleware = (section: Section, workloads: Workload[], schedule: Workload[]): Workload[] => {
  const newWorkloads: Workload[] = [];

  newWorkloads.push(...workloads);

  return newWorkloads;
}

// no consecutive 4 workload
export const consecutiveMiddleware: ScheduleMiddleware = (_, workloads: Workload[], schedule: Workload[]): Workload[] => {
  const newWorkloads: Workload[] = [];

  workloads.forEach(workload => {
    const m3 = schedule.find((w) => w.period === workload.period! - 3 && w.day === workload.day && w.teacher.name === workload.teacher.name && !!w.section);
    const m2 = schedule.find((w) => w.period === workload.period! - 2 && w.day === workload.day && w.teacher.name === workload.teacher.name && !!w.section);
    const m1 = schedule.find((w) => w.period === workload.period! - 1 && w.day === workload.day && w.teacher.name === workload.teacher.name && !!w.section);
    const p1 = schedule.find((w) => w.period === workload.period! + 1 && w.day === workload.day && w.teacher.name === workload.teacher.name && !!w.section);
    const p2 = schedule.find((w) => w.period === workload.period! + 2 && w.day === workload.day && w.teacher.name === workload.teacher.name && !!w.section);
    const p3 = schedule.find((w) => w.period === workload.period! + 3 && w.day === workload.day && w.teacher.name === workload.teacher.name && !!w.section);

    if (
      (m3 && m2 && m1) ||
      (m2 && m1 && p1) ||
      (m1 && p1 && p2) ||
      (p1 && p2 && p3)
    ) {
      return;
    }

    newWorkloads.push(workload);
  });

  return newWorkloads;
}

