import { Course, Section, SectionMiddleware } from "../types";

// map each course to a section
export function createSections(courses: Course[]): Section[] {
  return courses.map((course) => ({
    course,
    id: 1
  }));
}

// each section must have a maximum of 34 students
export const capacityMiddleware: SectionMiddleware = (sections: Section[]) => {
  const LIMIT = 34;
  const newSections: Section[] = [];

  sections.forEach(section => {
    const numSections = Math.ceil(section.course.students.length / LIMIT);

    for (let i = 0; i < numSections; i++) {
      newSections.push({
        id: i + 1,
        course: section.course,
      });
    }
  });

  return newSections;
}

// each section must meet a number of times per week, 
// sections that meet more than 5 times will have a double period
export const frequencyMiddleware: SectionMiddleware = (sections: Section[]) => {
  const newSections: Section[] = [];

  sections.forEach((section: Section) => {
    if (!section.course.frequency) return;

    let extraDays = section.course.frequency - 5;

    for (let i = 0; i < Math.min(section.course.frequency, 5); i++) {
      newSections.push({
        course: section.course,
        id: section.id,
        length: extraDays > 0 ? 2 : 1,
        day: i + 1
      });

      extraDays--;
    }
  });

  return newSections;
}
