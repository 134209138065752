import {useState} from 'react';
import { useSchedulerInput } from '../hooks/useSchedulerInput'
import { Teacher, Course, Section, Workload } from '../types';
import { formatCourses, formatTeachers } from '../utils/formatters';
import { capacityMiddleware, createSections, frequencyMiddleware } from '../utils/section-middlewares';
import { createWorkloads, dayMiddleware, periodMiddleware, scheduleMiddleware } from '../utils/workload-middlewares';

import './Scheduler.css';
import { teacherCoursesMiddleware, unassignedMiddleware, onceADayMiddleware, consecutiveMiddleware, teacherCoursesLimitMiddleware } from '../utils/schedule-middlewares';
import { assignSectionToWorkloads } from '../utils/helpers';


export const Scheduler = () => {
  const input = useSchedulerInput();
  const [workloads, setWorkloads] = useState<Workload[]>([]);

  function generateSchedule() {
    const courses: Course[] = formatCourses(input.courses.data || [], input.students.data || []);
    const teachers: Teacher[] = formatTeachers(input.teachers.data || [], courses);
    let sections: Section[] = createSections(courses);
    let workloads: Workload[] = createWorkloads(teachers);

    // apply section middlewares
    const sectionMiddlewares = [
      capacityMiddleware, 
      frequencyMiddleware
    ];
    sectionMiddlewares.forEach((middleware) => {
      sections = middleware(sections);
    });

    // apply workload middlewares
    const WorkloadMiddlewares = [
      dayMiddleware, 
      periodMiddleware, 
      scheduleMiddleware
    ];
    WorkloadMiddlewares.forEach((middleware) => {
      workloads = middleware(workloads);
    });

    // apply scheduling middlewares
    const schedulingMiddlewares = [
      unassignedMiddleware, 
      teacherCoursesMiddleware, 
      onceADayMiddleware, 
      consecutiveMiddleware, 
      teacherCoursesLimitMiddleware
    ];

    sections.forEach((section) => {
      let sectionWorkloads: Workload[] = [...workloads];

      schedulingMiddlewares.forEach((middleware) => {
        sectionWorkloads = middleware(section, sectionWorkloads, workloads);
      })

      assignSectionToWorkloads(section, sectionWorkloads)
    });

    setWorkloads(workloads);
  }

  function getTeachers() {
    const teachers = new Set<Teacher>();

    workloads.forEach((workload) => {
      teachers.add(workload.teacher);
    });

    return Array.from(teachers);
  }

  function getAssignedSection(teacher: Teacher, day: number, period: number) {
    const workload = workloads.find((workload) => {
      return workload.teacher.name === teacher.name && workload.day === day && workload.period === period;
    });

    if (workload?.section) {
      return `${workload?.section?.course.name} - ${workload?.section?.id}`;
    }
    else {
      return '';
    }
  }

  return (
    <div className="scheduler">
      <h1>Scheduler</h1>
      <div>
        <h2>Input</h2>
        <div className="scheduler__input">
          <label className="scheduler__upload">
            Student Course Preference
            <input type="file" onChange={(e) => input.students.parse(e.target.files![0])} />
          </label>
          <label className="scheduler__upload">
            Teacher Courses
            <input type="file" onChange={(e) => input.teachers.parse(e.target.files![0])} />
          </label>
          <label className="scheduler__upload">
            Course Codes
            <input type="file" onChange={(e) => input.courses.parse(e.target.files![0])} />
          </label>
          <label className="scheduler__upload">
            Rooms
            <input type="file" onChange={(e) => input.rooms.parse(e.target.files![0])} />
          </label>
        </div>
        <button className="scheduler__generate" type="button" onClick={generateSchedule}>Generate Schedule</button>
      </div>
      <div className="scheduler__table-wrapper">
        <table className="scheduler__teacher-table">
          <thead>
            <tr>
              <th>
                Teacher
              </th>
              <th>
                Day
              </th>
              {Array.from({ length: 9 }).map((_, i) => (
                <th key={i}>{i + 1}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {getTeachers().map((teacher: Teacher) => (
              <>
                {Array.from({ length: 5}).map((_, day) => (
                  <tr key={teacher.name + day}>
                    {day === 0 && <td rowSpan={5}>{teacher.name}</td>}
                    <td>{day+1}</td>
                    {Array.from({ length: 9 }).map((_, period) => (
                      <td>{getAssignedSection(teacher, day + 1, period + 1)}</td>
                    ))}
                  </tr>
              ))}
            </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
