import React from "react";
import { Scheduler } from "./components/Scheduler";
import "./App.css";
import { LocalStorageUtil, WARNING_KEY } from "./utils/localStorage";

LocalStorageUtil.setItem(WARNING_KEY, JSON.stringify([]));

function App() {
  return (
    <div className="App">
      <Scheduler />
    </div>
  );
}

export default App;
