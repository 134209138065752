// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
	font-family: Arial, sans-serif;
	margin: 20px;
}
.container {
	max-width: 600px;
	margin: auto;
	text-align: center;
}
table {
	border-collapse: collapse;
	width: 100%;
}
th,
td {
	padding: 8px;
}
.result-container {
	margin-top: 20px;
}

.actions {
	display: flex;
	justify-content: space-between;
}

label {
	cursor: pointer;
	margin-right: 30px;
}

.filters {
	display: flex;
	gap: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,YAAY;AACb;AACA;CACC,gBAAgB;CAChB,YAAY;CACZ,kBAAkB;AACnB;AACA;CACC,yBAAyB;CACzB,WAAW;AACZ;AACA;;CAEC,YAAY;AACb;AACA;CACC,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,8BAA8B;AAC/B;;AAEA;CACC,eAAe;CACf,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,UAAU;AACX","sourcesContent":["body {\n\tfont-family: Arial, sans-serif;\n\tmargin: 20px;\n}\n.container {\n\tmax-width: 600px;\n\tmargin: auto;\n\ttext-align: center;\n}\ntable {\n\tborder-collapse: collapse;\n\twidth: 100%;\n}\nth,\ntd {\n\tpadding: 8px;\n}\n.result-container {\n\tmargin-top: 20px;\n}\n\n.actions {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n\nlabel {\n\tcursor: pointer;\n\tmargin-right: 30px;\n}\n\n.filters {\n\tdisplay: flex;\n\tgap: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
