export const WARNING_KEY = "WARNINGS";
export const LocalStorageUtil = {
	setItem: (key: string, value: string): void => {
		localStorage.setItem(key, value);
	},

	getItem: (key: string): string | null => {
		return localStorage.getItem(key);
	},

	removeItem: (key: string): void => {
		localStorage.removeItem(key);
	},
};
