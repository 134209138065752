import { Course, CourseCSV, StudentsCSV, Teacher, TeacherCSV } from "../types";

export function formatCourses(courseCsv: CourseCSV[], studentCsv: StudentsCSV[]): Course[] {
  const courses: Course[] = [];

  courseCsv.forEach((course: CourseCSV) => {
    courses.push({
      name: course['Course Name'],
      code: course['Course Code'],
      frequency: isNaN(+course['# Meeting Times']) ? (course['# Meeting Times'] === 'N/A' ? 0 : +course['# Meeting Times'].split(' ')[2]) : +course['# Meeting Times'],
      students: []
    });
  });

  studentCsv.forEach((student: StudentsCSV) => {
    for (let i = 1; i <= 10; i++) {
      const code = student[i as keyof StudentsCSV];

      if (code) {
        const course = courses.find(c => c.code === code);

        if (course) {
          course.students.push(student['Student ID']);
        }
        else {
          console.warn(`Course code ${code} not found for student ${student['Student ID']}.`);
        }
      }
    }
  });

  return courses;
}

export function formatTeachers(teacherCsv: TeacherCSV[], courses: Course[]): Teacher[] {
  return teacherCsv.map((teacher) => {
    const teacherCourses: Course[][] = [];

    for (let i = 0; i <= 6; i++) {
      const courseName = teacher[i as keyof TeacherCSV];

      if (courseName) {
        const course = courses.find((c: Course) => c.name.toLowerCase() === courseName.toLowerCase());

        if (course) {
          teacherCourses.push([ course ]);
        }
        else {
          const courseNames = courseName.split('/');
          const teacherCourse: Course[] = [];

          courseNames.forEach((name: string) => {
            const course = courses.find((c: Course) => c.name.toLowerCase() === name.toLowerCase())

            if (!course) {
              console.warn(`Course ${name} not found for teacher ${teacher.Teacher}.`);
            }
            else {
              teacherCourse.push(course);
            }
          });

          teacherCourses.push(teacherCourse);
        }
      }
    }

    return {
      name: teacher.Teacher,
      schedule: teacher.Schedule,
      courses: teacherCourses
    };
  });
}
