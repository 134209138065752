import { useEffect } from "react";
import type { ParseMeta } from "papaparse";

import { useJSONParser } from "./useJSONParser";
import type { CourseCSV, StudentsCSV, TeacherCSV } from "../types";
import { LocalStorageUtil, WARNING_KEY } from "../utils/localStorage";

const _warnings = LocalStorageUtil.getItem(WARNING_KEY);
const warnings = _warnings ? JSON.parse(_warnings) : [];

export const useSchedulerInput = () => {
	const students = useJSONParser<StudentsCSV>();
	const teachers = useJSONParser<TeacherCSV>();
	const courses = useJSONParser<CourseCSV>();
	const rooms = useJSONParser();

	useEffect(() => {
		validate("Student course preferences", students.data, students.meta, [
			"Student ID",
		]);
	}, [students]);

	useEffect(() => {
		validate("Teacher courses", teachers.data, teachers.meta, [
			"Schedule",
			"Teacher",
		]);
	}, [teachers]);

	useEffect(() => {
		validate("Course codes", courses.data, courses.meta, [
			"Course Code",
			"Course Name",
			"# Meeting Times",
			"Room Locations",
		]);
	}, [courses]);

	useEffect(() => {
		validate("Rooms", rooms.data, rooms.meta, [
			"Teacher",
			"Main Room",
			"Possible Rooms",
		]);
	}, [rooms]);

	function validate(
		name: string,
		data: any,
		meta: ParseMeta | undefined,
		requiredFields: string[],
	) {
		if (data) {
			if (data.length === 0) {
				warnings.push(`${name} is empty!`);
				LocalStorageUtil.setItem(WARNING_KEY, JSON.stringify(warnings));
			}

			for (const field of requiredFields) {
				if (!meta?.fields?.includes(field)) {
					warnings.push(`${field} not found in ${name}!`);
					LocalStorageUtil.setItem(WARNING_KEY, JSON.stringify(warnings));
				}
			}
		}
	}

	return {
		students,
		teachers,
		courses,
		rooms,
	};
};
