// @ts-nocheck
import React, { useState, useRef } from 'react';
import { useSchedulerInput } from '../hooks/useSchedulerInput';
import type {
  Teacher,
  ExcessStudents,
  MappedCourse,
  MappedTeacherCoursesAndSections,
  StudentCourse,
  TeacherCourse,
  Course,
} from '../types';
import sampleMappedTeacherCoursesAndSections from '../teacherCourseData.json';

import './Scheduler.css';
import { LocalStorageUtil, WARNING_KEY } from '../utils/localStorage';

type ScheduleGrid = { [teacher: string]: { [day: number]: { [period: number]: string | null } } };

export const Scheduler = () => {
  const input = useSchedulerInput();
  const [warnings, setWarnings] = useState<string[]>([]);
  const scheduleRef = useRef<ScheduleGrid | null>(null);
  const teacherCourseArrRef = useRef<MappedTeacherCoursesAndSections[] | null>(null);

  const getStudentsBySection = (students: StudentCourse[], sectionSize: number, section: number) => {
    const totalGroups = Math.floor(students.length / sectionSize);
    if (section < 1 || section > totalGroups) {
      return [];
    }
    return students.slice((section - 1) * sectionSize, section * sectionSize);
  };

  const getExcessStudents = (students: StudentCourse[], sectionSize: number) => {
    const totalGroups = Math.floor(students.length / sectionSize);
    const floatingStudents = students.slice(totalGroups * sectionSize);
    return floatingStudents.map(student => student.studentId);
  };

  const getAssignedSection = (teacherName: string, day: number, period: number): string => {
    const teacherData = scheduleRef.current?.[teacherName];
    if (!teacherData) return '';

    const classCodeAndSection = teacherData[day]?.[period];
    // const maybeIctMarker = /Q(L?)P-\d$/.test(classCodeAndSection ?? '');
    const isTeachingIcp = Object.keys(teacherCourseArrRef.current?.find(teacher => teacher.Teacher === teacherName)?.courses ?? {}).some(courseAndSection => courseAndSection !== classCodeAndSection && courseAndSection.replace('QLP', '').replace('QLP', '') === classCodeAndSection);
    const decoratedCourseName = `${classCodeAndSection ?? ''}${isTeachingIcp ? '*' : ''}`;
    

    return decoratedCourseName;
  };

  const getTeachers = () => {
    return teacherCourseArrRef.current?.map(teacher => ({ name: teacher.Teacher, schedule: teacher.Schedule })) || [];
  };

  const generateScheduleV2 = () => {
    // Data preprocessing
    const teacherCourseArr: MappedTeacherCoursesAndSections[] = sampleMappedTeacherCoursesAndSections;

    // Create a schedule grid, namespaced by teacher.  Includes 'X' for early/late shift markers.
    const scheduleGrid: ScheduleGrid = {};
    for (const teacherData of teacherCourseArr) {
      const teacher = teacherData.Teacher;
      scheduleGrid[teacher] = {};
      for (let day = 1; day <= 5; day++) {
        scheduleGrid[teacher][day] = {};
        for (let period = 1; period <= 9; period++) {
          if ((teacherData.Schedule === 'EARLY' && period === 9) || (teacherData.Schedule === 'LATE' && period === 1)) {
            scheduleGrid[teacher][day][period] = 'X';
          } else {
            scheduleGrid[teacher][day][period] = null;
          }
        }
      }
    }

    const warningsArr: string[] = [];

    const isFreePeriod = (courseSlot: string | null) => courseSlot === null || courseSlot === 'X';
    const isICTCourse = (courseCode: string) => courseCode.endsWith('QP');
    const isLabCourse = (courseCode: string) => courseCode.endsWith('QL');
    const isPECourse = (courseCode: string) => courseCode.startsWith('P');
    const isScienceCourse = (courseCode: string) =>
      ['SBN11', 'SCN11', 'SPN11', 'SPN11X', 'SBN11X'].includes(courseCode);
    const isDoublePeriodCourse = (courseCode: string) =>
      ['AMN11', 'TQN21QJ'].some(c => courseCode.startsWith(c));
    const isAPCourse = (courseCode: string) => courseCode.endsWith('X');
    const getCourseFrequency = (course: Course | undefined) => {
      if (course === undefined || !course?.['# Meeting Times']) return [1];
      if (course['# Meeting Times'] === '2 or 3') return [2, 3];
      return [Number(course['# Meeting Times'])];
    };

    for (const teacherData of teacherCourseArr) {
      const { Teacher, Schedule, courses } = teacherData;
      for (let day = 1; day <= 5; day++) {
        scheduleGrid[Teacher][day] = scheduleGrid[Teacher][day] || {}; // Ensure day exists
      }

      for (const courseCodeAndSection in courses) {
        const course = courses[courseCodeAndSection];
        const [courseCode, section] = courseCodeAndSection.split('-');
        if (courseCode.endsWith('QL')) {
          // already allocated labs when allocating regular science classes
          continue;
        }
        if (courseCode.endsWith('QP') || courseCode.endsWith('QLP')) {
          // temporarily ignoring ICP classes
          continue;
        }
        const frequencies = getCourseFrequency(course);
        const isPE = isPECourse(courseCodeAndSection);
        const isDouble = isDoublePeriodCourse(courseCodeAndSection);
        const isScience = isScienceCourse(courseCode);
        const requiredMeetings = isPE ? 3 : frequencies[0]; // Use the first frequency from getCourseFrequency, or 3 for PE classes
        const startingPeriod = Schedule === 'EARLY' ? 1 : 2;
        let assignedDays = 0;

        for (let period = startingPeriod; period <= 9 && assignedDays < requiredMeetings; period++) {

          let availableDaysInPeriod = 0;
          const daysToSchedule = [];
          for (let day = 1; day <= 5; day++) {
            if (scheduleGrid[Teacher][day][period] === null) {
              // Rule 2: Check for 3 consecutive periods on this day
              const prevPeriodOccupied = period - 1 >= startingPeriod && scheduleGrid[Teacher][day][period - 1] !== null;
              const prevPrevPeriodOccupied = period - 2 >= startingPeriod && scheduleGrid[Teacher][day][period - 2] !== null;
              const prevPrevPrevPeriodOccupied = period - 3 >= startingPeriod && scheduleGrid[Teacher][day][period - 3] !== null;
              if (prevPeriodOccupied && prevPrevPeriodOccupied && prevPrevPrevPeriodOccupied) continue; // Skip if 3 consecutive periods are already scheduled

              // Check for double-period availability (Rule 7)
              const nextPeriodOccupied = period + 1 <= 9 && scheduleGrid[Teacher][day][period + 1] !== null;
              if (scheduleGrid[Teacher][day][period] === null && !nextPeriodOccupied && isDouble) {
                availableDaysInPeriod += 2; // we'll allocate two days off this
                daysToSchedule.push(day);
                continue;
              }

              if (scheduleGrid[Teacher][day][period] === null && !isDouble) {
                availableDaysInPeriod++;
                daysToSchedule.push(day);
              }
            }
          }

          // If enough days are available, schedule for the preferred number of days
          if (availableDaysInPeriod >= requiredMeetings) {
            for (let i = 0; i < requiredMeetings && assignedDays < requiredMeetings; i++) {
              const day = daysToSchedule[i];
              scheduleGrid[Teacher][day][period] = courseCodeAndSection;
              assignedDays++;
              if (isDouble) {
                scheduleGrid[Teacher][day][period + 1] = courseCodeAndSection; // Assign double period
                assignedDays++;
              }

              // right after allocating the science class, manually assign one meeting of the science lab in the period right before or after
              if (isScience && assignedDays === requiredMeetings) {
                const courseCode = courseCodeAndSection.split('-')[0];
                const section = courseCodeAndSection.split('-')[1];
                let labAllocated = false;

                // Try to allocate lab in the period before or after the science class, avoiding 4 consecutive periods
                for (let offset of [-1, 1]) {
                  const labPeriod = period + offset;
                  if (labPeriod >= startingPeriod && labPeriod <= 9) {
                    for (let day = 1; day <= 5; day++) {
                      if (scheduleGrid[Teacher][day][period] === courseCodeAndSection && scheduleGrid[Teacher][day][labPeriod] === null) {
                        // Check for 3 consecutive periods before assigning lab
                        const prevPeriodOccupied = labPeriod - 1 >= startingPeriod && scheduleGrid[Teacher][day][labPeriod - 1] !== null;
                        const prevPrevPeriodOccupied = labPeriod - 2 >= startingPeriod && scheduleGrid[Teacher][day][labPeriod - 2] !== null;
                        const prevPrevPrevPeriodOccupied = labPeriod - 3 >= startingPeriod && scheduleGrid[Teacher][day][labPeriod - 3] !== null;
                        if (!(prevPeriodOccupied && prevPrevPeriodOccupied && prevPrevPrevPeriodOccupied)) {
                          scheduleGrid[Teacher][day][labPeriod] = `${courseCode}QL-${section}`;
                          labAllocated = true;
                          break;
                        }
                      }
                    }
                    if (labAllocated) break; // Exit loop if lab is allocated
                  }
                }

                if (!labAllocated) {
                  warningsArr.push(`Could not allocate lab for course ${courseCodeAndSection} to teacher ${Teacher}`);
                }
              }
            }
          } else if (availableDaysInPeriod >= 2 && isPE && assignedDays < 2) { // Fallback to 2 days for PE if 3 not possible
            for (let i = 0; i < 2 && assignedDays < requiredMeetings; i++) {
              const day = daysToSchedule[i];
              scheduleGrid[Teacher][day][period] = courseCodeAndSection;
              assignedDays++;
            }
          }

          if (isPE && assignedDays >= 2) break; // Stop if we have at least 2 days for this PE class
        }

        if (assignedDays < requiredMeetings && !isPE) {
          warningsArr.push(`Could not assign all ${requiredMeetings} periods for course ${courseCodeAndSection} to teacher ${Teacher}`);
        }
      }
    }

    console.log('ScheduleGrid', scheduleGrid, JSON.stringify(scheduleGrid));
    scheduleRef.current = scheduleGrid;
    teacherCourseArrRef.current = teacherCourseArr;
    setWarnings(warningsArr);
  };

  return (
    <div className='scheduler'>
      <h1>Scheduler</h1>
      <div>
        <h2>Inputs</h2>
        <table className='csv-upload-table'>
          <tbody>
            <tr>
              <td>Student Course Preferences</td>
              <td>
                <input
                  type='file'
                  onChange={e => {
                    const file = e.target.files?.[0];
                    if (file) {
                      input.students.parse(file);
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Teacher Courses</td>
              <td>
                <input
                  type='file'
                  onChange={e => {
                    const file = e.target.files?.[0];
                    if (file) {
                      input.teachers.parse(file);
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Course Codes</td>
              <td>
                <input
                  type='file'
                  onChange={e => {
                    const file = e.target.files?.[0];
                    if (file) {
                      input.courses.parse(file);
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Rooms</td>
              <td>
                <input
                  type='file'
                  onChange={e => {
                    const file = e.target.files?.[0];
                    if (file) {
                      input.rooms.parse(file);
                    }
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button className='scheduler__generate' type='button' onClick={generateScheduleV2}>
          Generate Schedule
        </button>
      </div>
      {warnings.length > 0 && (
        <details style={{ margin: '10px 0' }}>
          <summary>({warnings.length ?? 0}) Warnings</summary>
          <div>
            {warnings.map((warning, idx) => (
              <div style={{ margin: 0 }} key={`warning-key-${idx + 12}`}>
                - {warning}
              </div>
            ))}
          </div>
        </details>
      )}
      {Object.entries(scheduleRef.current ?? {}).length > 0 && (
        <div className='scheduler__table-wrapper'>
          <table className='scheduler__teacher-table' style={{ fontWeight: 'bold' }}>
            <colgroup>
              <col />
              <col />
              <col />
              {Array.from({ length: 9 }).map((_, i) => (
                <col key={`period-col-${i + 1}`} />
              ))}
            </colgroup>
            <thead>
              <tr>
                <th>Teacher</th>
                <th>Day</th>
                {Array.from({ length: 9 }).map((_, i) => (
                  <th key={`period-${i + 1}`}>{i + 1}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.entries(scheduleRef.current ?? {}).map(([teacherName, teacherData]) => (
                <React.Fragment key={teacherName}>
                  {['M', 'T', 'W', 'Th', 'F'].map((day, i) => (
                    <tr key={teacherName + day}>
                      {i === 0 && (
                        <td rowSpan={5} className='text-center'>
                          {teacherName}
                        </td>
                      )}
                      <td className='text-center'>{day}</td>
                      {Array.from({ length: 9 }).map((_, period) => (
                        <td key={`${teacherName}-${day}-${period.toString()}`} className='text-center'>
                          {getAssignedSection(teacherName, i + 1, period + 1)}
                        </td>
                      ))}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
